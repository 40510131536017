import React from 'react'
import CardList from '../components/CardList'
import Container from '../components/Container'
import styled from "styled-components";
import Layout from '../components/DefaultLayout';
import {graphql} from "gatsby"
import GalleryCard from "../components/GalleryCard";
import Lightbox from 'react-images';


const Hero = styled.img`
  width: 50vw;
  margin: auto;
`

const ImgList = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0 auto;
  &::after {
    content: '';
    flex: 0 0 32%;
  }
`;

const Post = styled.div`
  border: 1px solid ${props => props.theme.colors.secondary};
  border-radius: 2px;
  margin: 0 0 1em 0;
  width: 100%;
  transition: background 0.2s;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: 0 0 49%;
    margin: 0 0 2vw 0;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex: 0 0 32%;
  }
  &:hover {
    h2{
        color: ${props => props.theme.colors.base};
    }
  }
  .a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.base};
    text-decoration: none;
    .gatsby-image-wrapper {
      height: 0;
      padding-bottom: 60%;
    }
  }
`

class Awards extends React.Component {
    constructor() {
        super();

        this.state = {
            lightboxIsOpen: false,
            currentImage: 0,
        };

        this.closeLightbox = this.closeLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
        this.gotoImage = this.gotoImage.bind(this);
        this.handleClickImage = this.handleClickImage.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
    }

    openLightbox(index, event) {
        event.preventDefault();
        this.setState({
            currentImage: index,
            lightboxIsOpen: true,
        });
    }

    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }

    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }

    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }

    gotoImage(index) {
        this.setState({
            currentImage: index,
        });
    }

    handleClickImage() {
        if (this.state.currentImage === this.props.images.length - 1) return;

        this.gotoNext();
    }


    render() {
        const galleries = this.props.data.allContentfulGallery.edges;
        const images = this.props.data.allContentfulGallery.edges[0].node.images.map(image => {
            return {
                src: image.sizes.src,
                srcSet: image.sizes.srcSet,
                caption: image.description
            }
        });
        return (
            <Layout>
                <Container>
                    {galleries.map(({node: gallery}) => (
                        <CardList key={gallery}>
                            {gallery.images.map((image, i) => (
                                <GalleryCard
                                    key={i}
                                    image={image}
                                    onClick={(e) => this.openLightbox(i, e)}
                                />
                            ))}
                        </CardList>
                    ))}
                </Container>
                <Lightbox
                    currentImage={this.state.currentImage}
                    images={images}
                    isOpen={this.state.lightboxIsOpen}
                    onClickImage={this.handleClickImage}
                    onClickNext={this.gotoNext}
                    onClickPrev={this.gotoPrevious}
                    onClickThumbnail={this.gotoImage}
                    onClose={this.closeLightbox}
                />
            </Layout>
        )
    }
}

export const query = graphql`
    query AwardsQuery {
        allContentfulGallery(
            limit: 1000
            filter: { slug: { eq : "awards-gallery" } } 
        ) {
            edges {
                node {
                    name
                    id
                    slug
                    images{
                        id
                        description
                        sizes(maxWidth: 300) {
                            ...GatsbyContentfulSizes_withWebp_noBase64
                        }
                    }
                }
            }
        }

    }
`

export default Awards
